import { Controller } from "stimulus"
import printJS from 'print-js'

export default class extends Controller {
  print(event) {
    event.preventDefault();

    var element = this.element
    if (this.element.dataset.model == 'Package' && this.element.dataset.printingTemplateName == 'box_label') {
      var afterPreviewClose = function(){
        // Reload the page, thinks a better way to do this
        Turbolinks.visit(location.toString());
      };

      printJS({
        printable: this.element.dataset.pdfUrl,
        type: 'pdf',
        showModal: true,
        modalMessage: this.element.dataset.loadingText,
        onPrintDialogClose: afterPreviewClose
      })
    } else {
      printJS({
        printable: this.element.dataset.pdfUrl,
        type: 'pdf',
        showModal: true,
        modalMessage: this.element.dataset.loadingText
      })

    }
  }
}
