// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import 'alpinejs'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as Turbo from "@hotwired/turbo"

Turbo.session.drive = false

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.consumer = consumer

StimulusReflex.initialize(application, { consumer, isolate: true, debug: process.env.RAILS_ENV === 'development' })

import debounced from 'debounced'
debounced.initialize({ input: { wait: 400 } })

window.Rails = Rails;
$.ajaxSetup({
  headers: {
    'X-CSRF-Token': Rails.csrfToken()
  }
});

// show progress indicator sooner
Turbolinks.BrowserAdapter.prototype.showProgressBarAfterDelay = function () {
  return this.progressBarTimeout = setTimeout(this.showProgressBar, 10);
};
document.addEventListener('page:loaded', function () {
  document.dispatchEvent(new Event('turbolinks:load'));
  document.dispatchEvent(new Event('ajax:complete'));
})
document.addEventListener('stimulus-reflex:before', event => {
  window.safeStartTurbolinksProgress();
})
document.addEventListener('stimulus-reflex:after', event => {

  window.safeStopTurbolinksProgress();

  var end = document.location.href.indexOf('?') === -1 ? document.location.href.length : document.location.href.indexOf('?');
  var currentUrl = document.location.href.slice(0, end);

  if (window.eventUrl) {
    var eventUrl = window.eventUrl.slice(0, window.eventUrl.indexOf('?'));
  }

  if (currentUrl === eventUrl) {
    history.pushState({ turbolinks: true, url: window.eventUrl }, '', window.eventUrl);
  }  
})
document.addEventListener('ajax:before', function () {
  window.safeStartTurbolinksProgress();
});
document.addEventListener('ajax:complete', function () {
  window.safeStopTurbolinksProgress();
});
document.addEventListener('turbolinks:push-history', function (event) {
  window.eventUrl = event.detail.url;
});

$(document).on('turbolinks:before-cache', function () {
  document.querySelectorAll('[data-turbolinks-temporary], .invalid-feedback, #toast-container').forEach((element) => {
    element.style.display = 'none';
  });

  window.clearEphemeralElements();

  $('.modal.show').modal('hide');
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
});
