class CameraScanner {
  code_reader = null;
  selected_device_id = null;
  scanning = false;
  beep_success = null;
  beep_error = null;
  source_success = null;
  source_error = null;

  constructor() {
    //
  }

  reset() {
    if (this.code_reader) this.code_reader.reset();    
  }

  close() {
    $('.scanner').removeClass('scanner-show');
    this.hideAll();
    this.reset();
  }

  hideAll() {
    $('.scan-button').hide();
    $('.scan-red-line').hide();
    $('.scan-loader').hide();
  }

  start() {
    this.scanning = true;
    $('#source_select').empty();
    this.reset();
    this.getDevices();
    this.run();
    this.hideAll();
    $('.scan-red-line').show();
  }

  restart() {
    this.scanning = true;
    this.hideAll();
    $('.scan-red-line').show();
  }

  getDevices() {
    var $this = this;
    this.code_reader.listVideoInputDevices()
      .then((video_input_devices) => {
        if ($this.selected_device_id == null) {
          var selected = 0;
          if (video_input_devices.length > 1) selected = 1;
          $this.selected_device_id = video_input_devices[selected].deviceId;
        }
        if (video_input_devices.length >= 1) {
          video_input_devices.forEach((element) => {
            const source_option = document.createElement('option')
            source_option.text = element.label;
            source_option.value = element.deviceId;
            if (element.deviceId === $this.selected_device_id) source_option.selected = "selected";
            $('#source_select').append(source_option);
          });

          $('#source_select').on('change', function() {
            $this.selected_device_id = source_select.value;
            $this.run();
          });
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }
  
  run() {
    var $this = this;
    this.code_reader.decodeFromVideoDevice($this.selected_device_id, 'video', (result, err) => {
      if (result && $this.scanning) {
        $this.scanning = false;
        var code = result.text;
        $this.hideAll();
        $('.scan-loader').show();
        window.scan.sendEvent(code);
        try {
          $this.playAudio();
        } catch (e) {
          console.log(e);
        }
      }
    });
  }

  async load(link) {
    var result = await this.request('GET', link);
    return result;
  }

  request(method, url) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.responseType = 'arraybuffer';
      xhr.addEventListener('load', function (r) {
        var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        audioCtx.decodeAudioData(
          xhr.response, 
          function (buffer) {
            resolve(buffer);
          }
        );
      });
      xhr.send();
    });
  }

  play(buffer) {
    try {
      var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      var source = audioCtx.createBufferSource();
      source.buffer = buffer;
      source.connect(audioCtx.destination);
      source.loop = false;
      source.start();
    } catch(e) {
      console.log(e);
    }
  }

  playErrorAudio() {
    this.play(this.beep_error);
  }

  playAudio() {
    this.play(this.beep_success);
  }
  
  async loadBeeps() {
    this.beep_success = await this.load('https://skutally.s3.amazonaws.com/beep_success.mp3');
    this.beep_error = await this.load('https://skutally.s3.amazonaws.com/beep_error.mp3');
  }

  events() {
    var $this = this;
    $('.scan-button').on('click', function() {
      $this.hideAll();
      $('.scan-red-line').show();
      $this.scanning = true;
    });

    $('.close-button button').on('click', function() {
      $this.close();
    });

    $('#start_button').on('click', function() {
      $('.scanner').addClass('scanner-show');
      $this.start();
    });
  }

  init() {
    var $this = this;
    this.reset();

    this.code_reader = new ZXing.BrowserMultiFormatReader();
    
    this.loadBeeps();

    $(document).on('turbolinks:load load:components', function () {  
      $this.events();
    });
  }
}

export default CameraScanner;
