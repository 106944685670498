import { Controller } from "stimulus";
import { Grid } from "ag-grid-community";
import {LicenseManager} from "ag-grid-enterprise";

LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-058029}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Skutally}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{skutally_backoffice}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{skutally_backoffice}_need_to_be_licensed___{skutally_backoffice}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{7_May_2025}____[v3]_[0102]_MTc0NjU3MjQwMDAwMA==b89f9575acb4f51eede9d77f7532f55e");

export default class extends Controller {
  static targets = ['gridContainer', 'loading'];

  currentPage = 0;
  totalCount = 0;
  totalLoaded = 0;
  columnDefs = [];
  filterModel = undefined;
  columnStates = undefined;
  flatColumnDefs = [];
  loadedAllPages = false;

  initialize() {
    this.#fetchCurrentPageData();
  }

  #fetchCurrentPageData() {
    fetch(this.#paginatedReportUrl(this.currentPage))
      .then(response => response.json())
      .then(data => {
        this.#processData(data);

        if(!this.#isPaginated || !data.rows.length) {
          this.loadedAllPages = true;
          this.#onGridReady();
          return;
        }

        this.totalCount = data.total_count;
        this.currentPage += 1;
        this.#fetchCurrentPageData();
      });
  }

  exportCsv() {
    if(this.context.controller.gridOptions) {
      this.context.controller.gridOptions.api.exportDataAsCsv();
    }
  }

  saveFilters() {
    if(this.context.controller.gridOptions) {
      document.getElementById("col_state").value = JSON.stringify(this.context.controller.gridOptions.columnApi.getColumnState());
      document.getElementById("filter_model").value = JSON.stringify( this.context.controller.gridOptions.api.getFilterModel());
    }
  }

  loadFilters(event) {
    if(this.context.controller.gridOptions) {
      const element = event.currentTarget
      const content = JSON.parse(element.dataset.content)


      const colState = content['col_state']
      Object.keys(colState).forEach(function(key, index) {
        const obj = this[key]
        Object.keys(obj).forEach(function(key2, index) {
          if (this[key2] == "") this[key2] = null;
          if (this[key2] == "true") this[key2] = true;
          if (this[key2] == "false") this[key2] = false;
        }, obj);
      }, colState);

      this.context.controller.gridOptions.columnApi.applyColumnState({ state: Object.values(colState), applyOrder: true });
      this.context.controller.gridOptions.api.setFilterModel(content['filter_model']);
    }
  }

  resetFilters() {
    if(this.context.controller.gridOptions) {
      this.context.controller.gridOptions.api.setFilterModel(null);
      this.context.controller.gridOptions.columnApi.resetColumnState();
    }
  }

  #processData(data) {
    if(this.currentPage === 0) {
      this.columnDefs = data.headers.map(header => this.#parseColumnDefinition(header));
      this.flatColumnDefs = this.columnDefs.reduce((acc, curr) => {
        if(curr.children) {
          return acc.concat(curr.children);
        } else {
          return acc.concat(curr);
        }
      }, []);
    }

    this.#addGridData(data);
  }

  #addGridData(unpreparedRowData) {
    this.totalLoaded += unpreparedRowData.rows.length;
    if (this.currentPage === 0) {
        return this.#initializeGrid(unpreparedRowData);
    }

    const rowData = this.#prepareRowData(unpreparedRowData);
    this.context.controller.gridOptions.api.applyTransactionAsync(
      { add: rowData },
      () => { this.#onGridReady() }
    );

    this.#onGridReady();
  }

  #initializeGrid(unpreparedRowData) {
    const rowData = this.#prepareRowData(unpreparedRowData);

    this.context.controller.gridOptions = {
      columnDefs: this.columnDefs,
      defaultColDef: {
        sortable: true,
        enableRowGroup: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [{
            filter: 'agSetColumnFilter',
          },
          {
            filter: 'agTextColumnFilter'
          }]
        }
      },
      rowData: rowData,
      onGridReady: this.#onGridReady.bind(this),
      onFilterChanged: this.#onGridReady.bind(this),
      sideBar: {
        toolPanels: [
          {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        ],
        defaultToolPanel: "closed"
      },
      rowGroupPanelShow: 'onlyWhenGrouping'
    };

    if(this.hasLoadingTarget) this.loadingTarget.remove();

    const eGridDiv = this.gridContainerTarget;
    new Grid(eGridDiv, this.gridOptions);
  }

  #prepareRowData(rowData) {
    return rowData.rows.map(row => {
      return row.reduce((obj, value, index) => {
        obj[this.flatColumnDefs[index].field] = value;
        return obj;
      }, {});
    });
  }

  #onGridReady(_event) {
    let pinnedBottomData = this.#generatePinnedBottomData(this.context.controller.gridOptions.api, this.context.controller.gridOptions.columnApi);
    this.context.controller.gridOptions.api.setPinnedBottomRowData([pinnedBottomData]);
  }

  #parseColumnDefinition(column, parentHeader = null) {
    let columnDef = { headerName: column.label, hide: column.hide, extra: column.extra };

    columnDef.menuTabs = ["filterMenuTab", "columnsMenuTab", "generalMenuTab"]

    if(column.headers) {
      columnDef.children = column.headers.map(header => this.#parseColumnDefinition(header, columnDef.headerName));
      return columnDef;
    }

    columnDef.field = [
      parentHeader?.replace(/[^a-zA-Z0-9]/g, '_')?.toLowerCase(),
      column.label.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase()
    ].join('_');

    if(column.extra?.footer) {
      columnDef.cellRenderer = this.#postCellFormatter.bind(this);
    }

    if(column.format === 'currency' || (column.format === 'link' && column.extra?.format_text == 'currency')) {
      columnDef.comparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
        let parsedA = isNaN(parseFloat(valueA)) ? 0 : parseFloat(valueA);
        let parsedB = isNaN(parseFloat(valueB)) ? 0 : parseFloat(valueB);
        if (parsedA == parsedB) return 0;
        return (parsedA > parsedB) ? 1 : -1;
      };
    }

    if(column.format === 'date') {
      columnDef.comparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
        let parsedA = new Date(valueA);
        let parsedB = new Date(valueB);
        if (parsedA == parsedB) return 0;
        return (parsedA > parsedB) ? 1 : -1;
      };
    }

    if(column.format === 'currency'){
      columnDef.cellRenderer = this.#currencyCellRenderer.bind(this);
    } else if(column.format === 'link') {
      columnDef.cellRenderer = this.#linkCellRenderer.bind(this);
      columnDef.valueFormatter = this.#linkValueFormatter.bind(this);
    }

    return columnDef;
  }

  #currencyCellRenderer(params) {
    if(params.node.rowPinned === 'bottom' && params.value === null) return;

    return this.#postCellFormatter(params, this.#currencyFormatter.format(params.value));
  }

  #linkCellRenderer(params) {
    if(params.node.rowPinned === 'bottom') {
      return this.#postCellFormatter(params, params.value);
    }

    const template = params.colDef.extra.link.template;
    const data = params.data;
    var url = undefined

    try{
      url = template.replace(/{{([^}]+)}}/g, (match, p1) => data[p1]);
    }catch(e){
      return this.#postCellFormatter(params, params.value);
    }

    let formatted_value = params.value
    if (params.colDef.extra.format_text == 'currency') {
      formatted_value = this.#currencyFormatter.format(params.value)
    }

    if(params.value != null) {
      if(params.colDef.extra.link.remote_template && params.value != null) {
        const remoteUrl = params.colDef.extra.link.remote_template.replace(/{{([^}]+)}}/g, (match, p1) => data[p1]);

        return `<a href="${url}" class="link"
        data-controller="remote-modal"
        data-remote-modal-url="${remoteUrl}"
        data-action="click->remote-modal#show"
        data-remote="true">${formatted_value}</a>`;
      } else {
        return `<a href="${url}" target="_blank" class="link"> ${formatted_value}</a>`;
      }
    }
  }
  #linkValueFormatter(params) {
    return params.value?.label;
  }

  // This will format the cell value. We only use it at the moment to format the pinned bottom row so that it is bold.
  #postCellFormatter(params, value) {
    let formattedValue = value || params.value;
    if(params.node.rowPinned !== 'bottom') return formattedValue;

    if(params.colDef.extra?.footer && formattedValue !== 'undefined') {
      if(params.colDef.extra?.format_text == 'currency'){
        formattedValue = this.#currencyFormatter.format(formattedValue)
      }
      return `<strong>${formattedValue}</strong>`;
    } else {
      return '';
    }
  }

  // This will prepare the pinned bottom row and request to calculate its values.
  #generatePinnedBottomData(gridApi, gridColumnApi) {
    // generate a row-data with null values
    let result = {};
    gridColumnApi.getAllGridColumns().forEach(item => {
      if(item.colDef.cellDataType === 'text') {
        result[item.colId] = '';
      } else if(item.colDef.cellDataType === 'number') {
        result[item.colId] = undefined;
      }
    });

    return this.#calculatePinnedBottomData(result, gridApi, gridColumnApi);
  }

  // This will calculate the values for the pinned bottom row.
  #calculatePinnedBottomData(target, gridApi, gridColumnApi){
    let columnsWithFooter = gridColumnApi.getAllGridColumns().filter(column => column.colDef.extra?.footer);

    if(this.#isPaginated && !this.loadedAllPages) {
      let percentage = Math.round((this.totalLoaded / this.totalCount) * 100);
      target[columnsWithFooter[0].colId] = `Loading (${percentage}%)...`;

      return target;
    }

    columnsWithFooter.forEach(column => {
      let sum = 0
      let count = 0;

      gridApi.forEachNodeAfterFilter((rowNode) => {
        if(column.colDef.extra?.footer !== 'count') sum += Number(rowNode.data[column.colId]);
        count += 1;
      });

      if(column.colDef.extra?.footer === 'sum') {
        target[column.colId] = Math.round(sum * 100) / 100;
      } else if(column.colDef.extra?.footer === 'count') {
        target[column.colId] = `Count: ${count}`;
      } else if(column.colDef.extra?.footer === 'average') {
        target[column.colId] = `Avg: ${count > 0 ? (sum / count).toFixed(2) : 0}`;
      }
    });

    return target;
  }

  #paginatedReportUrl(page) {
    if(!this.#isPaginated) return this.#reportUrl;

    const separator = this.#reportUrl.includes('?') ? '&' : '?';
    return `${this.#reportUrl}${separator}page=${page + 1}`;
  }

  get #isPaginated() {
    return this.data.get("paginated") === "true";
  }

  get #reportUrl() {
    return this.data.get("url");
  }

  get #currencyFormatter() {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }
}
