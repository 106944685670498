import { Controller } from "stimulus";
import { GeniusClient } from "cayan";
import Rails from '@rails/ujs';

/*
 * Base library:
 *   https://github.com/cohubinc/cayan-js
 * CurrentScreen values:
 *   https://docs.tsysmerchant.com/knowledge-base/faqs/what-are-the-genius-status-call-possible-responses
 */
export default class extends Controller {
  static targets = ['form', 'token', 'validationKey']

  async initialize() {
    const controller = this;
    if (document.documentElement.hasAttribute("data-turbolinks-preview")) return;

    controller.reachedPaymentScreen = false;
    controller.genius = await GeniusClient.createInstance(controller.geniusConfig).catch(e => {
      alert('There was a problem with the terminal: ' + e.message);
    });

    controller.genius.CheckStatus().then(initCheckStatus => {
      if (initCheckStatus.Status != 'Online') {
        console.log(initCheckStatus);
        alert('Terminal not connected, busy or not found. Please, try again or contact support.');

        return controller.canceled();
      }

      controller.genius.InitiateTransaction(controller.transportKey).then((result) => {
        if (result.Token) {
          if (controller.responded) return;

          controller.responded = true;
          controller.stopCheckingStatus();
          controller.tokenTarget.setAttribute('value', result.Token)
          controller.validationKeyTarget.setAttribute('value', result.ValidationKey)

          Rails.fire(controller.formTarget, 'submit');
        } else if (result.Error) {
          console.log(result);
          alert(`There has been a problem with the terminal: ${result.Error.Message} (${result.Error.ErrorCode})`);
          controller.canceled();
        } else if (result.includes('<ErrorCode>40000</ErrorCode>')) {
          console.log(result);
          alert('Unable to initiate the transaction (Invalid Transport Key)');
          controller.canceled();
        }
      }).catch(e => {
        console.log(e);
        alert(`There has been a problem with the transaction: ${e.Status}`);
        controller.canceled();
      })

      controller.scheduleStatusCheck();
    }).catch(e => {
      console.log(e);
      alert(`There has been a problem with the transaction: ${e.Status}`);
      controller.canceled();
    })
  }

  disconnect() {
    this.responded = true;
    this.stopCheckingStatus();
  }

  async cancel() {
    const controller = this;

    let statusResponse = await controller.genius.CheckStatus()
    if (statusResponse.Status != 'Online' || statusResponse.CurrentScreen == '00') {
      return controller.canceled();
    }

    controller.genius.Cancel().then(response => {
      if (response.Status == 'Cancelled') {
        controller.canceled();
      } else {
        controller.unableToCancel(response)
      }
    }).catch(response => { controller.unableToCancel(response) });
  }

  unableToCancel(response) {
    this.stopCheckingStatus();

    if (this.responded) return;
    console.log(response);
    alert('Unable to cancel the current transaction: ' + response.ResponseMessage);
  }

  canceled() {
    if (this.responded) return;

    this.responded = true;
    this.stopCheckingStatus();
    Turbolinks.visit(this.cancelUrl, { action: 'replace' });
  }

  scheduleStatusCheck() {
    const controller = this;

    if (controller.responded) return;
    controller.checkStatusTimer = setTimeout(() => {
      if (controller.responded) return;

      controller.genius.CheckStatus().then(response => {
        if (controller.responded) return;

        if (!controller.reachedPaymentScreen && response.CurrentScreen != '00') {
          controller.reachedPaymentScreen = true;
          controller.scheduleStatusCheck();
        } else if ((controller.reachedPaymentScreen && response.CurrentScreen == '00') || response.Status == 'Cancelled' || response.Status == 'POSCancelled') {
          controller.canceled();
        } else {
          controller.scheduleStatusCheck();
        }
      })
    }, 500)
  }

  stopCheckingStatus() {
    if (this.checkStatusTimer) clearInterval(this.checkStatusTimer);
  }

  get geniusConfig() {
    const dataset = this.context.element.dataset;

    return {
      MerchantName: dataset.merchantName,
      MerchantSiteId: dataset.merchantSiteId,
      MerchantKey: dataset.merchantKey,
      CEDHostname: dataset.cedHostname
    };
  }

  get transportKey() {
    return this.context.element.dataset.transportKey;
  }

  get cancelUrl() {
    return this.context.element.dataset.cancelUrl;
  }
}
